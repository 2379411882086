import type { Organization } from "@/types/organizations";

export const useOrgM = () => {
	const { viewOrganization: viewOrganizationCall } = useOrgMApi();

	const viewOrganization = async (organization_id: string) => {
		const { response, error } = await viewOrganizationCall<Organization>(organization_id);

		if (error) throw error;
		return response!.data;
	};

	return {
		viewOrganization,
	};
};
