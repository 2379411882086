import type { Platforms } from "@/types/platform";
import { pickTextColor, deduceBtnRounding } from "@/utils/helpers.js";

export const usePlatformStore = defineStore(
	"platform",
	() => {
		const { getPlatformSettingsFromURLPlatform } = useECMApi();
		const { $sentry } = useNuxtApp();

		interface PlatformData {
			platform_settings: Platforms[];
			public_address: any[];
			public_contact: any;
		}

		const { mode } = useRuntimeConfig().public;
		const platform = ref<PlatformData | null>(null);
		const platformInitialized = ref(false);

		const platformName = computed(() => platform.value?.platform_settings[0]?.platform_config.name || "");
		const platformId = computed(() => platform.value?.platform_settings[0].id || "");
		const platformOrgId = computed(() => platform.value?.platform_settings[0].organization_id || "");

		const plfm = computed(() => {
			const primaryFont = platform.value?.platform_settings[0]?.platform_branding.platform_font.primary_font;
			const secondaryFont = platform.value?.platform_settings[0]?.platform_branding.platform_font.secondary_font;
			const isEditorial = platform.value?.platform_settings[0]?.platform_branding.style === "Editorial";
			const colorSet = platform.value?.platform_settings[0]?.platform_branding.platform_colorset;
			const btnBgColor =
				platform.value?.platform_settings[0]?.platform_branding?.platform_input.primary_color || "#000000";

			return {
				style: platform.value?.platform_settings[0]?.platform_branding.style,
				isEditorial,
				mainFont: primaryFont || "Lato",
				secondaryFont: isEditorial && secondaryFont ? secondaryFont : primaryFont || "Lato",
				colors: {
					text: colorSet?.standard_text || "#000",
					titles: colorSet?.titles || "#000",
					subtitles: colorSet?.subtitles || "#505055",
					links: colorSet?.links || "#0C7CE6",
				},
				btns: {
					bg: btnBgColor,
					text: pickTextColor(btnBgColor),
					rounding: deduceBtnRounding(platform.value?.platform_settings[0]?.platform_branding?.platform_input.shape),
				},
			};
		});

		const imgixEcmOdcUrl = computed(() => {
			return mode === "production" || mode === "staging"
				? "https://tengiva-ecm-odc-prod.imgix.net"
				: "https://tengiva-ecm-odc-dev.imgix.net";
		});

		const imgixEcmWpcUrl = computed(() => {
			const imgixEcmWpcDomain =
				mode === "production" || mode === "staging"
					? "tengiva-ecm-wpc-prod.imgix.net"
					: "tengiva-ecm-wpc-dev.imgix.net";
			return `https://${imgixEcmWpcDomain}/${platformId.value}`;
		});

		const faviconUrl = computed(
			() => `${imgixEcmWpcUrl.value}/${platform.value?.platform_settings[0]?.platform_branding.favicon_id}`
		);

		async function getPlatform(platformUrl: string) {
			try {
				const { response } = await getPlatformSettingsFromURLPlatform(platformUrl);

				platform.value = response?.data as PlatformData;
			} catch (error) {
				$sentry?.captureException("Cannot get platform settings from url platform");
			}
		}

		return {
			platformInitialized,
			platform,
			platformName,
			platformId,
			platformOrgId,
			plfm,
			imgixEcmOdcUrl,
			imgixEcmWpcUrl,
			faviconUrl,
			getPlatform,
		};
	}
	// {
	// 	persist: {
	// 		storage: persistedState.sessionStorage,
	// 	},
	// }
);
