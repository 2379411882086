// create organization related store
import { defineStore } from "pinia";
import type { Organization } from "@/types/organizations";

export const useOrgStore = defineStore(
	"org",
	() => {
		const { viewOrganization } = useOrgM();
		const organization = ref<Organization | null>(null);

		async function setOrganization(id: string) {
			const response = await viewOrganization(id);
			organization.value = response;
		}

		return {
			organization,
			setOrganization,
		};
	},
	{
		persist: {
			storage: persistedState.sessionStorage,
		},
	}
);
